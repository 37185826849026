import React,{useRef} from "react";
import img1 from '../../assets/img/Homepage/profile.png'
import img2 from '../../assets/img/Homepage/filter.png'
import img3 from '../../assets/img/Homepage/icons8-collaboration-96.png'
import img4 from '../../assets/img/Homepage/brainstorming.png'
import img5 from '../../assets/img/Homepage/videoconference.png'

const Section1Feature = () => {
  const featureRef = useRef(null);
  return (
      <div id="featureSection" ref={featureRef} class="bg-[#0f172a] py-12 lg:py-20">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-5xl lg:text-center flex flex-col justify-center items-center">
      <h2 class="text-base font-semibold leading-7 text-white bg-teal-400 px-3  rounded-xl uppercase mb-4 lg:mb-8">
      Features and Benefits
       </h2>
      <h1 class="lg:text-5xl text-4xl md:text-5xl font-bold tracking-tight text-gray-100 text-center"> 
        <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#91cbd7] via-emerald-300 to-gray-100">Enriching the Ecosystem with Expertise</span>
      </h1>
      <p class="mt-6 text-md lg:text-lg text-gray-300 max-w-4xl text-center">
      Whether you're on the path to assessing your organization's maturity level or you're a seasoned professional looking to offer your assessment services, Spectra Hub is your gateway to a world of opportunity. Connect, collaborate, and transform with the best in the business. Discover the power of community and expertise with Spectra Hub – your partner in navigating the complexities of digital and business transformation.
      </p>
    </div>
    <div class="mx-auto py-20">
    <div className="flex flex-wrap justify-center gap-8 row-gap-8 ">
        <div className="sm:text-center w-96">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img src={img1} className="w-10 lg:w-14"/>
          </div>
          <h6 className="mb-2 font-semibold text-white text-lg lg:text-xl leading-5">Interactive Profiles</h6>
          <p className="max-w-md mb-3 text-sm lg:text-lg text-gray-300 sm:mx-auto">
          Create detailed profiles showcasing your organization's expertise, services, and case studies. This transparency builds trust and facilitates meaningful connections.
          </p>
         
        </div>
        <div className="sm:text-center w-96">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img src={img2} className="w-12 lg:w-16"/>
          </div>
          <h6 className="mb-2 font-semibold text-white text-lg lg:text-xl leading-5">Advanced Search and Filters</h6>
          <p className="max-w-md mb-3 text-sm lg:text-lg text-gray-300 sm:mx-auto">
          Whether you're looking for an assessor or offering services, our advanced search functionality allows you to quickly find the right matches based on criteria like industry expertise, location, and service offerings.
          </p>
        
        </div>
        <div className="sm:text-center w-96">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img src={img3} className="w-10 lg:w-16"/>
          </div>
          <h6 className="mb-2 font-semibold text-white text-lg lg:text-xl leading-5">
          Direct Messaging and Collaboration</h6>
          <p className="max-w-md mb-3 text-sm lg:text-lg text-gray-300 sm:mx-auto">
          Communicate directly with potential clients or service providers through our secure messaging system. This feature ensures that discussions remain focused, private, and productive.
          </p>
         
        </div>
        <div className="sm:text-center w-96">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img src={img4} className="w-10 lg:w-14"/>
          </div>
          <h6 className="mb-2 font-semibold text-white text-lg lg:text-xl leading-5">
          Knowledge Sharing</h6>
          <p className="max-w-md mb-3 text-sm lg:text-lg text-gray-300 sm:mx-auto">
          Contribute to and benefit from a wealth of resources, including articles, white papers, and webinars, all aimed at enhancing your understanding of organizational maturity and transformation strategies.
          </p>
         
        </div>
        <div className="sm:text-center w-96">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
          <img src={img5} className="w-10 lg:w-12"/>
          </div>
          <h6 className="mb-2 font-semibold text-white text-lg lg:text-xl leading-5">
          Event and Webinar Hosting</h6>
          <p className="max-w-md mb-3 text-sm lg:text-lg text-gray-300 sm:mx-auto">
          Host or participate in events and webinars directly within Spectra Hub, offering a powerful platform for thought leadership and community engagement.
          </p>
         
        </div>
        </div>
    </div>
  </div>
</div>
 
  );
};

export default Section1Feature;
