import React from 'react'
import img1 from '../../assets/img/Homepage/apply.png'
import img2 from '../../assets/img/Homepage/add.png'
import img3 from '../../assets/img/Homepage/community-engagement.png'
export default function StepsSection() {
  return (
    <div className='bg-[#0f172a]'>
         <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-1 mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-400">
           
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
         
        Getting Started
        </h2>
     
      </div>
      <div className="grid gap-8 row-gap-0 lg:grid-cols-3">
        <div className="relative text-center">
          <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
           <img src={img1} className='w-12'/>
          </div>
          <h6 className="mb-2 text-2xl text-white font-bold">Apply for a License</h6>
          <p className="max-w-md mb-3  md:text-lg text-gray-300 sm:mx-auto">
          To become a licensed assessor or consultant on Spectra Hub, fill out our application form, providing details about your organization, areas of expertise, and previous success stories.
          </p>
         
          <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
            <svg
              className="w-8 text-gray-700 transform rotate-90 lg:rotate-0"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <line
                fill="none"
                strokeMiterlimit="10"
                x1="2"
                y1="12"
                x2="22"
                y2="12"
              />
              <polyline
                fill="none"
                strokeMiterlimit="10"
                points="15,5 22,12 15,19 "
              />
            </svg>
          </div>
        </div>
        <div className="relative text-center">
          <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
          <img src={img2} className='w-12'/>
          </div>
          <h6 className="mb-2 text-2xl text-white font-bold">Profile Creation</h6>
          <p className="max-w-md mb-3 md:text-lg text-gray-300 sm:mx-auto">
          Once approved, you'll be invited to create a comprehensive profile for your organization. This profile serves as your public-facing portfolio, showcasing your services to potential clients.
          </p>
         
          <div className="top-0 right-0 flex items-center justify-center h-24 lg:-mr-8 lg:absolute">
            <svg
              className="w-8 text-gray-700 transform rotate-90 lg:rotate-0"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <line
                fill="none"
                strokeMiterlimit="10"
                x1="2"
                y1="12"
                x2="22"
                y2="12"
              />
              <polyline
                fill="none"
                strokeMiterlimit="10"
                points="15,5 22,12 15,19 "
              />
            </svg>
          </div>
        </div>
        <div className="relative text-center">
          <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-20 sm:h-20">
          <img src={img3} className='w-12'/>
          </div>
          <h6 className="mb-2 text-2xl text-white font-bold">Engage and Connect</h6>
          <p className="max-w-md mb-3  md:text-lg text-gray-300 sm:mx-auto">
          Start engaging with the community, respond to assessment requests, and utilize Spectra Hub's features to grow your network and business.
          </p>
         
        </div>
      </div>
    </div>
    </div>
  )
}
