import Flogo1 from '../../assets/img/Homepage/features/premium-quality.png'
import Flogo2 from '../../assets/img/Homepage/features/promotion.png'
import Flogo3 from '../../assets/img/Homepage/features/insight.png'
import Flogo4 from '../../assets/img/Homepage/features/brainstorming.png'
const Features = () => {
  return (
    <section className="bg-[#0f172a] ">
    <div className="container px-6 py-10 mx-auto">
    <div class="mx-auto max-w-5xl lg:text-center flex flex-col justify-center items-center">
    <h2 class="text-base font-semibold leading-7 text-white bg-teal-400 px-3  rounded-xl uppercase mb-4 lg:mb-8">
    Core Features and Personas
       </h2>
    <h1 class="lg:text-5xl text-4xl md:text-5xl font-bold tracking-tight text-gray-100 text-center"> 
        <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#91cbd7] via-emerald-300 to-gray-100">Enriching the Ecosystem with Expertise</span>
      </h1>

      <p class="mt-6 text-md lg:text-lg text-gray-300 max-w-4xl text-center">
      Organizations and independent consultants specializing in maturity assessments will find Spectra Hub an invaluable platform for showcasing their expertise and connecting with businesses seeking transformation.
      </p>
      </div>
        

        <div className="flex flex-wrap justify-center gap-8 mt-20">
            
            <div className="w-96  p-8 space-y-3 border-2 border-green222 rounded-xl  shadow-xl hover:shadow-green222">
            <img src={Flogo1} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-green222 text-center">	Profile Spotlight and Premium Badges</h1>

                <p className=" font-medium text-gray-300">
                Within Spectra Hub, enterprise plan subscribers from Spectrum 360 will receive spotlight treatment and premium badges on their profiles. This not only elevates their visibility to potential clients but also signifies their commitment and capability in driving transformation.
                </p>
                

                
            </div>
            <div className="w-96  p-8 space-y-3 border-2 border-green222 rounded-xl  shadow-xl hover:shadow-green222">
            <img src={Flogo2} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-green222 text-center">		Product and Service Promotion</h1>

                <p className=" font-medium text-gray-300 lg:py-7">
                These organizations can promote their products or services directly to Spectrum 360 users. Whether it's a unique assessment tool, consulting service, or a workshop, Spectra Hub offers targeted advertising opportunities within practice areas, ensuring relevant visibility.
                </p>
                

                
            </div>
        </div>
    </div>
    {/* FEATURE ________----------------------------------------------2 */}
    <div className="container px-6 py-10 mx-auto">
    <div class="mx-auto max-w-5xl lg:text-center flex flex-col justify-center items-center">
    
    <h1 class="lg:text-5xl text-4xl md:text-5xl font-bold tracking-tight text-gray-100 text-center"> 
        <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#91cbd7] via-emerald-300 to-gray-100"> Community of Practice Area</span>
      </h1>

      <p class="mt-6 text-md lg:text-lg text-gray-300 max-w-4xl text-center">
      A core feature of Spectra Hub is its ability to host a dynamic and interactive community of practice. This community is a collective where users can share and consume best practices, videos, articles, participate in events and conferences, and engage in forum discussions and Q&A.
      </p>
      </div>
        

        <div className="flex flex-wrap justify-center gap-8 mt-20">
            
            <div className="w-96  p-8 space-y-3 border-2 border-green222 rounded-xl  shadow-xl hover:shadow-green222">
            <img src={Flogo3} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-green222 text-center">		Content Feeding to Spectrum 360</h1>

                <p className=" font-medium text-gray-300 lg:py-7">
                Content published on Spectra Hub, including videos, articles, and event announcements, will be feed directly into Spectrum 360. Users subscribed to specific practice areas on Spectrum 360 will automatically receive these updates, ensuring they stay informed and engaged with the latest insights and trends.
                </p>
                

                
            </div>
            <div className="w-96  p-8 space-y-3 border-2 border-green222 rounded-xl  shadow-xl hover:shadow-green222">
            <img src={Flogo4} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-green222 text-center">Direct Engagement and Consulting</h1>

                <p className=" font-medium text-gray-300">
                Post-matchmaking, assessor organizations can directly engage with Spectrum 360 organizations to offer consulting services. This seamless integration ensures that recommendations can be effectively implemented, with assessors handholding organizations through the transformation process.
                </p>
                

                
            </div>
        </div>
    </div>
</section>
  )
}

export default Features