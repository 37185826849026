import React from "react";
import img1 from "../../assets/img/Homepage/2149546178.jpg";
import img_icon1 from "../../assets/img/Homepage/expertise.svg";
import img_icon2 from "../../assets/img/Homepage/network-svgrepo-com.svg";
import img_icon3 from "../../assets/img/Homepage/collaborate-svgrepo-com.svg";

const Personalized = () => {
  return (
    <section class=" pt-10  bg-[#0f172a]">
    <div class="container px-6 lg:px-20 py-10 mx-auto">
        <div class="lg:flex lg:items-center place-items-center">
            <div class="w-full space-y-12 lg:w-1/2 ">
                <div>
                    <h1 class="text-2xl font-semibold capitalize lg:text-4xl text-white"><span className=" text-[#91cbd7]">For Professionals and Organizations Offering Assessment Services</span> </h1>

                    <div class="mt-2">
                        <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                        <span class="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
                        <span class="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
                    </div>
                   

                </div>
                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2 text-blue-500 rounded-xl md:mx-4  bg-blue-500">
                       <img src={img_icon1} className="w-8 lg:w-20"></img>
                      
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold  capitalize text-white">Showcase Your Expertise</h1>

                        <p class="mt-3 lg:text-lg text-gray-300">
                        Spectra Hub provides a platform for assessors and consulting firms to highlight their specialties, past successes, and unique methodologies. By joining our community, you gain visibility among a targeted audience of organizations actively seeking your services.
                        </p>
                    </div>
                </div>

                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2 text-blue-500  rounded-xl md:mx-4  bg-blue-500">
                    <img src={img_icon2} className=" w-8 lg:w-16"></img>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold  capitalize text-white">Expand Your Reach</h1>

                        <p class="mt-3 lg:text-lg text-gray-300">
                        Become part of an exclusive network that connects you with potential clients across the globe. Spectra Hub's reach extends to a diverse array of industries and organizations of all sizes, from startups to large enterprises.
                        </p>
                    </div>
                </div>
                <div class="md:flex md:items-start md:-mx-4">
                    <span class="inline-block p-2 text-blue-500 rounded-xl md:mx-4  bg-blue-500">
                    <img src={img_icon3} className=" w-8 lg:w-20"></img>
                    </span>

                    <div class="mt-4 md:mx-4 md:mt-0">
                        <h1 class="text-xl font-semibold  capitalize text-white">Collaborate and Grow</h1>

                        <p class="mt-3  lg:text-lg text-gray-300">
                        Engage with other professionals to exchange ideas, share best practices, and stay at the forefront of industry trends. Spectra Hub fosters a collaborative environment that encourages growth, learning, and the development of new assessment methodologies.
                        </p>
                    </div>
                </div>
              
            </div>

            <div class="hidden lg:flex lg:items-center lg:w-1/2 lg:justify-center">
                <img class="w-[28rem] h-[28rem] object-cover xl:w-[34rem] xl:h-[34rem] rounded-full" src={img1} alt=""/>
            </div>
        </div>

        <hr class="my-12 border-gray-700"/>

     
    </div>
</section>
  );
};

export default Personalized;
