import React, { useRef,useState } from "react";
import Modal from '../../pages/Modal/Modal'
import Baneer_img from "../../assets/img/Homepage/banner-img.png";
export default function HeroSection2() {
  const aboutRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  // Modal
   
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  
  return (
    <div id="aboutSection" ref={aboutRef}>
    <section className="py-12 lg:py-2  bg-[#0f172a]  lg:pb-10">
        <div className="px-4 mx-auto md:px-6 lg:px-10 max-w-4xl lg:max-w-7xl">
          <div className="relative flex flex-col-reverse lg:flex-row lg:flex lg:items-center">
            <div className="lg:w-2/4 md:w-2/5 lg:pt-10">
              {/* <p className="text-sm font-normal tracking-widest text-gray-300 uppercase">A Hub for Designers, Developers & Marketers</p> */}
              <h2 className="max-w-xl mb-6  text-3xl font-bold tracking-tight text-white lg:text-6xl sm:leading-none">
            Spectra Hub:
              <span className="inline-block text-transparent bg-clip-text bg-gradient-to-r from-[#91cbd7] via-emerald-300 to-gray-100">
              The Assessor and Social Connector Portal
              </span>
            </h2>
            <p className="max-w-xl mt-4 text-xl font-normal md:text-lg text-gray-100 sm:mt-8">
            Spectra Hub stands at the core of Maturity Spectrum 360, serving
                as a vibrant, interactive portal designed for both organizations
                seeking assessments and professionals offering assessment
                services. This platform facilitates a dynamic exchange of
                insights, methodologies, and best practices among its users,
                fostering a community geared towards driving digital and
                business transformation.
            </p>
              <div className="flex flex-col items-center pt-10 md:flex-row">
              <a
              onClick={openModal}
                className="inline-flex cursor-pointer items-center justify-center w-full h-12 px-6 mb-3 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto md:mr-4 md:mb-0 bg-emerald-500 hover:bg-emerald-300 focus:shadow-outline focus:outline-none"
              >
                Apply Now
              </a>

              </div>
              <div>
                <div className="inline-flex items-center pt-6 mt-8  sm:pt-10 sm:mt-14">
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke-width="1.5"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 7.00003H21M21 7.00003V15M21 7.00003L13 15L9 11L3 17"
                      stroke="url(#a)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="a"
                        x1="3"
                        y1="7.00003"
                        x2="22.2956"
                        y2="12.0274"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop
                          offset="0%"
                          style={{ stopColor: "rgb(6 182 212 / 0)" }}
                        />
                        <stop
                          offset="100%"
                          style={{ stopColor: "rgb(6 182 212 / 0)" }}
                        />
                      </linearGradient>
                    </defs>
                  </svg>

                  {/* <span className="ml-2 text-base font-normal text-white"> 42 new design inspiration was added last week </span> */}
                </div>
              </div>
            </div>

            <div className="mt-8 md:absolute md:mt-0 md:top-10 lg:mt-0  lg:top-0   md:right-0">
              <img
                className="w-full max-w-xs mx-auto sm:max-w-[400px] md:max-w-[540px] lg:max-w-[650px]  "
                src={Baneer_img}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    {/* Modal */}
    <Modal isOpen={isModalOpen} closeModal={closeModal} />
  </div>
  );
}
