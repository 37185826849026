import { React, useState } from "react";
import Modal from "../../pages/Modal/Modal";
import checkgrey_img from "../../assets/img/check-grey.svg";
import arrow_img from "../../assets/img/arrow-right.svg";
import img1 from "../../assets/img/abstract-1.jpg";
import img2 from "../../assets/img/abstract-2.jpg";
import img3 from "../../assets/img/abstract-3.jpg";

export default function Index() {
  const [isModalOpen, setModalOpen] = useState(false);

  // Modal
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div id="pricingSection">
      <div className="lg:py-5 text-white bg-[#0f172a]">
        <main className="max-w-7xl mx-auto pt-10 pb-28 px-8">
          <div className="max-w-4xl mx-auto mb-14 text-center">
            <h1 className="text-4xl font-semibold mb-6 lg:text-5xl">
              Spectra Hub{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#91cbd7] via-emerald-300 to-gray-100">License Plans</span>
            </h1>
            <p className="text-lg text-gray-300 font-normal">
              Spectra Hub offers a unique opportunity for assessment service
              providers and consultants to showcase their expertise to a wide
              audience, engage with potential clients, and contribute to a
              thriving community dedicated to organizational transformation. By
              obtaining a license, you gain access to advanced features,
              exclusive tools, and a network of organizations seeking your
              services.
            </p>
            <p className="text-lg text-gray-300 font-normal">We offer various license tiers to suit your organization's size, expertise, and outreach goals. Each tier is designed to maximize your visibility, engagement, and impact within the Spectra Hub community.</p>
          </div>

          <div className="flex flex-col justify-between items-center lg:flex-row lg:items-start">
            <div className="w-full flex-1 mt-8 p-8 order-1 bg-white shadow-xl rounded-3xl sm:w-96 lg:w-full lg:order-1 lg:rounded-r-none">
              <div className="mb-2 pb-7 flex items-center border-b border-gray-300">
                <img src={img1} alt="" className="rounded-3xl w-20 h-20" />
                <div className="ml-5">
                  <span className="block text-lg lg:text-2xl text-black font-semibold">
                    Basic
                  </span>
                  <span>
                    <span className="font-medium text-gray-500 text-xl align-top">
                      $
                    </span>
                    <span className="text-lg lg:text-3xl text-black font-bold">
                      999
                    </span>
                  </span>
                  <span className="text-gray-500 font-medium ">
                    / year
                  </span>
                </div>
              </div>
              <p className="text-black font-semibold py-2">
              Small firms or individual consultants offering assessment services.  
              </p>
              <ul className="mb-7 font-medium text-lg md:text-xl text-gray-500">
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="" />
                  <span className="ml-3">Create a profile</span>
                </li>
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="" />
                  <span className="ml-3">Post feeds</span>
                </li>
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="" />
                  <span className="ml-3">Offer up to 2 public events or webinars per year</span>
                </li>
                <li className="flex mb-4 md:mb-[30px]">
                  <img src={checkgrey_img} alt="" />
                  <span className="ml-3">Limited direct messaging features</span>
                </li>
                <li className="flex mb-4 md:mb-[30px]">
                  <img src={checkgrey_img} alt="" />
                  <span className="ml-3">Host 1 public community of practice area.</span>
                </li>
              </ul>
              <a
                onClick={openModal}
                className="flex justify-center cursor-pointer items-center bg-indigo-600 hover:bg-indigo-500 rounded-xl py-6 px-4 text-center text-white text-xl"
              >
                Subscribe
                <img src={arrow_img} alt="" className="ml-2" />
              </a>
            </div>

            <div className="w-full flex-1 mt-8 p-8 order-2 shadow-xl rounded-3xl bg-gray-800 text-gray-400 sm:w-96 lg:w-full lg:order-2 lg:mt-0">
              <div className="mb-2 pb-8 flex items-center border-b border-gray-600">
                <img src={img2} alt="" className="rounded-3xl w-20 h-20" />
                <div className="ml-5">
                  <span className="block text-lg lg:text-3xl font-semibold text-white">
                    Enterprise
                  </span>
                  <span>
                    <span className="font-medium text-gray-500 text-xl align-top">
                      $
                    </span>
                    <span className="text-lg lg:text-3xl font-bold text-white">
                      4999
                    </span>
                  </span>
                  <span className="font-medium text-white">
                    / year
                  </span>
                </div>
              </div>
              <p className="text-white font-semibold py-2">
              Large service providers seeking maximum exposure and strategic opportunities.    
              </p>
              <ul className="mb-10 font-medium text-lg md:text-xl text-white">
                <li className="flex mb-6">
                  <img src={checkgrey_img} alt="" />
                  <span className="ml-3 ">
                    Premium profile placement
                  </span>
                </li>
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="" />
                  <span className="ml-3">Unlimited events and webinars</span>
                </li>
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="" />
                  <span className="ml-3"> Ability to publish Ads to the target organizations (Either product or solutions) </span>
                </li>
                <li className="flex mb-4 md:mb-[140px]">
                  <img src={checkgrey_img} alt="" />
                  <span className="ml-3">Access to advanced analytics and strategic support services</span>
                </li>
                
              </ul>
              <a
                onClick={openModal}
                className="flex justify-center cursor-pointer items-center bg-indigo-600 hover:bg-indigo-500 rounded-xl py-6 px-4 text-center text-white text-2xl"
              >
                Subscribe
                <img src={arrow_img} alt="" className="ml-2" />
              </a>
            </div>

            <div className="w-full  flex-1 mt-8 p-8 order-4 bg-white shadow-xl  rounded-3xl sm:w-96 lg:w-full lg:order-4 lg:rounded-l-none">
              <div className="mb-2 pb-7 flex items-center border-b border-gray-300">
                <img src={img3} alt="" className="rounded-3xl w-20 h-20" />
                <div className="ml-5">
                  <span className="block text-lg lg:text-3xl font-semibold text-black">
                    Professional
                  </span>
                  <span>
                    <span className="font-medium text-xl text-gray-500 align-top">$&thinsp;</span>
                    <span className="text-lg lg:text-3xl font-bold text-black">
                      2499
                    </span>
                  </span>
                  <span className="font-medium text-gray-500">
                    / year
                  </span>
                </div>
              </div>
              <p className="text-black font-semibold py-2">
                Established firms looking for increased visibility and engagement opportunities.
              </p>
              <ul className="mb-11 font-medium text-gray-500 text-lg md:text-xl">
                <li className="flex mb-6">
                  <img src={checkgrey_img} alt="" />
                  <span className="ml-3">Enhanced profile features</span>
                </li>
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="" />
                  <span className="ml-3">Unlimited posting</span>
                </li>
                <li className="flex mb-4">
                  <img src={checkgrey_img} alt="" />
                  <span className="ml-3">Up to 5 public events or webinars</span>
                </li>
                <li className="flex mb-4 md:mb-[138px]">
                  <img src={checkgrey_img} alt="" />
                  <span className="ml-3">
                    Advanced direct messaging and networking features
                  </span>
                </li>
              </ul>
              <a
                onClick={openModal}
                className="flex justify-center cursor-pointer items-center bg-indigo-600 hover:bg-indigo-500 rounded-xl py-6 px-4 text-center text-white text-2xl"
              >
                Subscribe
                <img src={arrow_img} alt="" className="ml-2" />
              </a>
            </div>
          </div>
        </main>
      </div>
      {/* Modal */}
      <Modal isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
}
