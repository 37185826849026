import Plogo1 from '../../assets/img/Homepage/persona/interactive.png'
import Plogo2 from '../../assets/img/Homepage/persona/engagement.png'
import Plogo3 from '../../assets/img/Homepage/persona/success.png'
import Plogo4 from '../../assets/img/Homepage/persona/personalized-learning.png'
import Plogo5 from '../../assets/img/Homepage/persona/forum.png'
import Plogo6 from '../../assets/img/Homepage/persona/event.png'
import Plogo7 from '../../assets/img/Homepage/persona/social (2).png'


const Persona = () => {
  return (
    <section className="bg-[#0f172a] ">
    <div className="container px-6 py-10 mx-auto">
    <div class="mx-auto max-w-5xl lg:text-center flex flex-col justify-center items-center">
    <h2 class="text-base font-semibold leading-7 text-white bg-teal-400 px-3  rounded-xl uppercase mb-4 lg:mb-8">
    A Dual-Purpose Portal
       </h2>
    <h1 class="lg:text-5xl text-4xl md:text-5xl font-bold tracking-tight text-gray-100 text-center"> 
        <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#91cbd7] via-emerald-300 to-gray-100">For Organizations Offering Assessment Services</span>
      </h1>

      <p class="mt-6 text-md lg:text-lg text-gray-300 max-w-4xl text-center">
      Spectra Hub provides a unique platform for these organizations to showcase their expertise, connect with potential clients, and contribute to a rich ecosystem of knowledge sharing. Features tailored to these users include:
      </p>
      </div>
        

        <div className="flex flex-wrap justify-center gap-8 mt-20">
            
            <div className="w-96  p-8 space-y-3 border-2 border-green222 rounded-xl  shadow-xl hover:shadow-green222">
            <img src={Plogo1} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-green222 text-center">	Interactive Profiles</h1>

                <p className="font-medium text-gray-300 lg:py-8">
                Enhanced with premium badges for enterprise plan holders, these profiles allow service providers to highlight their expertise, past projects, and unique value propositions. Premium badges and the option to promote products or services directly on Spectrum 360 ensure high visibility.
                </p>
                

                
            </div>
            <div className="w-96  p-8 space-y-3 border-2 border-green222 rounded-xl  shadow-xl hover:shadow-green222">
            <img src={Plogo2} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-green222 text-center">			Direct Engagement with Spectrum 360 Users</h1>

                <p className="font-medium text-gray-300">
                Through a seamless integration, when a Spectrum 360 user selects an assessor from Spectra Hub, the platform facilitates direct engagement. This allows assessors to understand the specific needs of the organization and tailor their consulting services accordingly, creating a personalized pathway to implement recommended strategies.
                </p>
                

                
            </div>
            <div className="w-96  p-8 space-y-3 border-2 border-green222 rounded-xl  shadow-xl hover:shadow-green222">
            <img src={Plogo3} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-green222 text-center">Promotion of Services</h1>

                <p className=" font-medium text-gray-300 lg:py-8">
                Beyond standard listings, enterprise users can feature their services, products, or events prominently within Spectrum 360, reaching a targeted audience actively seeking transformation solutions. This includes the ability to run ads or feature content in a way that’s integrated naturally with the user experience.
                </p>
                

                
            </div>
        </div>
    </div>
    {/* FEATURE ________----------------------------------------------2 */}
    <div className="container px-6 py-10 mx-auto">
    <div class="mx-auto max-w-5xl lg:text-center flex flex-col justify-center items-center">
    
    <h1 class="lg:text-5xl text-4xl md:text-5xl font-bold tracking-tight text-gray-100 text-center"> 
        <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#91cbd7] via-emerald-300 to-gray-100"> Community of Practice</span>
      </h1>

      <p class="mt-6 text-md lg:text-lg text-gray-300 max-w-4xl text-center">
      Spectra Hub is also designed to foster a community where professionals across various sectors can share insights, best practices, and learn from one another. 
      </p>
      </div>
        

        <div className="flex flex-wrap justify-center gap-8 mt-20">
            
            <div className="w-96  p-8 space-y-3 border-2 border-green222 rounded-xl  shadow-xl hover:shadow-green222">
            <img src={Plogo4} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-green222 text-center">		Content Sharing and Learning</h1>

                <p className=" font-medium text-gray-300">
                A repository for videos, articles, case studies, and whitepapers that span across practice areas, allowing users to stay at the forefront of industry trends and methodologies.
                </p>
                

                
            </div>
            <div className="w-96  p-8 space-y-3 border-2 border-green222 rounded-xl  shadow-xl hover:shadow-green222">
            <img src={Plogo5} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-green222 text-center">				Forums and Q&A</h1>

                <p className="font-medium text-gray-300">
                Dedicated spaces for discussions, forums, and Q&A sessions encourage peer-to-peer advice, problem-solving, and sharing of innovative solutions to common challenges.
                </p>
                

                
            </div>
            {/* ---------- */}
            <div className="w-96  p-8 space-y-3 border-2  border-green222 rounded-xl  shadow-xl hover:shadow-green222">
            <img src={Plogo6} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-green222 text-center">					Events and Webinars</h1>

                <p className=" font-medium text-gray-300">
                A calendar of industry events, conferences, and webinars that users can both contribute to and attend. This supports continuous professional development and networking opportunities.
                </p>
                

                
            </div>
            <div className="w-96  p-8 space-y-3 border-2 border-green222 rounded-xl  shadow-xl hover:shadow-green222">
            <img src={Plogo7} alt="" className='w-16 mx-auto'  />

                <h1 className="text-xl font-semibold  capitalize text-green222 text-center">				Subscription Feeds</h1>

                <p className=" font-medium text-gray-300">
                Spectrum 360 users can subscribe to specific practice areas within Spectra Hub, ensuring they receive updates, posts, and event notifications that match their interests and needs. This direct feed into Spectrum 360 ensures relevant content reaches the right audience, enhancing the value and applicability of shared knowledge.
                </p>
                

                
            </div>
        </div>
    </div>
</section>
  )
}

export default Persona