import React from "react";

import HeroSection2 from "./HeroSection2";
import LastSection from "./LastSection";

import { Fade, Slide } from "react-awesome-reveal";
import Section1 from "./Section1Feature";
import Section2 from "./Personalized";
import Section3 from "./Benchmark";

import Pricing from '../Pricing/index'

import StepsSection from "./StepsSection";
import Features from "./Features";
import Persona from "./Persona";
export default function index() {
  return (
    <div>
      <Fade duration={500}  triggerOnce>
     
        <HeroSection2/>
        {/* <HeroSection3 /> */}
      </Fade>
     
      <Section1 />
      <Features />
      <Persona />
   
      <Section2/>

      {/* <Section3/> */}
 
      <StepsSection/>
     <Pricing/>
      <LastSection/>
    </div>
  );
}
